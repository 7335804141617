<template>
  <div class="content-wrapper__layout">
    <side-nav></side-nav>
    <div class="content-wrapper__main-content-wrapper content-wrapper__main-content-wrapper--31">
      <section class="main-content">
        <div class="section">
          <div class="tabs">
            <div :class="['tab', type == 'post' ? ' tab--active' : '']" @click.prevent="changeType('post', 2)">Publicación</div>
            <div :class="['tab', type == 'question' ? ' tab--active' : '']" @click.prevent="changeType('question', 1)">Pregunta</div>
          </div>
          <div v-if="type=='post'">
            <div class="box box--no-shadow">
              <form class="form" enctype="multipart/form-data">
                <fieldset class="form__set">
                  <legend class="form__legend">Publicación</legend>
                  <div class="form__group">
                    <label class="form__label" for="post-title">Título de la Publicación</label>
                    <input type="text" class="form__input" id="post-title" v-model="post.title">
                  </div>
                  <div class="tabs">
                    <div :class="['tab', questionType == 'text' ? ' tab--active' : '']" @click.prevent="changeQuestionType('text')">Texto</div>
                    <!--<div :class="['tab', questionType == 'imagen' ? ' tab--active' : '']" @click.prevent="changeQuestionType('imagen')">Imagen</div>-->
                    <div :class="['tab', questionType == 'video' ? ' tab--active' : '']" @click.prevent="changeQuestionType('video')">Video</div>
                  </div>
                  <div v-if="questionType == 'text'">
                    <div class="form__group">
                      <RichEditor :options="options" @content="setEditorContent"></RichEditor>
                    </div>
                  </div>
                  <div v-else-if="questionType == 'imagen'">
                    <div class="form__group">
                      <label class="form__label">Selecciona una imagen</label>
                      <input class="form__input" type="file" />
                    </div>
                  </div>
                  <div v-else-if="questionType == 'video'">
                    <div class="form__group">
                      <label for="videoUrl" class="form__label">URL del video</label>
                      <input class="form__input" type="text" @input="ChangeVideoPreview()" v-model="videoUrl" />
                    </div>
                    <div id="videoPreview"></div>
                  </div>
                  <div class="form__group">
                    <label class="form__label" for="link">Enlace externo <small class="text-alt">(Opcional)</small></label>
                    <input type="url" class="form__input" id="link" v-model.trim="post.url" placeholder="Ingresa un enlace">
                  </div>
                  <div class="form__group">
                    <label class="form__label" for="file">Archivo adjunto <small class="text-alt">(Opcional)</small></label>
                    <div class="grid grid-2">
                      <input ref="inputFile" type="file" class="form__input" accept="image/jpg , image/jpeg , image/png , application/pdf" id="file" @change="attachFile($event)">
                      <div class="text-right" v-if="post.file">
                        <button class="btn btn--danger btn--xs" type="button" @click.prevent="removeFile">Eliminar archivo</button>
                      </div>
                    </div>
                </div>
                </fieldset>
                <fieldset class="form__set">
                  <legend class="form__legend">Sobre la publicación</legend>
                  <div class="grid grid-2">
                    <div class="form__group">
                      <label class="form__label">Causas <small class="text-alt">(3 máximo)</small></label>
                      <Multiselect v-model="post.causes"
                        :mode="'multiple'"
                        :hideSelected="false"
                        :required="false"
                        :options="causes"
                        valueProp="id"
                        :searchable="true"
                        :noResultsText="'No se encontraron resultados'"
                        :closeOnSelect="false"
                        :max="3"
                        :placeholder="'Seleccione'"
                        ref="causes"
                      >
                        <template v-slot:multiplelabel="{ values }">
                          <div class="multiselect-multiple-label">
                            {{ `${values.length} ${values.length > 1 ? 'causas seleccionadas' : 'causa seleccionada'} `}}
                          </div>
                        </template>
                        <template v-slot:option="{ option }">
                          {{option.name}}<span class="tag tag--primary tag--pull--right post__tag">{{option.total}}</span>
                        </template>
                      </Multiselect>
                    </div>
                    <div class="form__group">
                      <label class="form__label">Áreas de interés <small class="text-alt">(3 máximo)</small></label>
                      <Multiselect v-model="post.areas"
                        :mode="'multiple'"
                        :hideSelected="false"
                        :required="false"
                        :options="areas"
                        valueProp="id"
                        :searchable="true"
                        :noResultsText="'No se encontraron resultados'"
                        :closeOnSelect="false"
                        :max="3"
                        :placeholder="'Seleccione'"
                        ref="areas"
                      >
                        <template v-slot:multiplelabel="{ values }">
                          <div class="multiselect-multiple-label">
                            {{ `${values.length} ${values.length > 1 ? 'áreas de interés seleccionadas' : 'área de interés seleccionada'} `}}
                          </div>
                        </template>
                        <template v-slot:option="{ option }">
                          {{option.name}}<span class="tag tag--primary tag--pull--right post__tag">{{option.total}}</span>
                        </template>
                      </Multiselect>
                    </div>
                  </div>
                  <div class="form__group">
                    <label class="form__label" for="post_tags">Etiquetas <small class="text-alt">(Opcional)</small> </label>
                    <Multiselect id="post_tags"
                      v-model="post.tags"
                      :required="false"
                      label="name"
                      valueProp="id"
                      :searchable="true"
                      :noResultsText="'No se encontraron resultados'"
                      :mode="'tags'"
                      :createTag="true"
                      :appendNewOption="true"
                      :max="5"
                      :closeOnSelect="false"
                      :placeholder="'Comienza a escribir para agregar etiquetas'"
                      :min-chars="1"
                      :resolve-on-load="true"
                      :delay="100"
                      :options="tagList"
                      @search-change="async (query) => {
                        return await fetchTags(query)
                      }"
                      ref="tags"
                    >
                      <template v-slot:tag="{ option, handleTagRemove, disabled }">
                        <div class="multiselect-tag is-user" :style="[checkHex(option.hex) ? {'background': option.hex} : {'background': colors[post.tags.indexOf(option.id)]}]">
                          {{ option.name }}
                          <span v-if="!disabled"
                            class="multiselect-tag-remove"
                            @mousedown.prevent="handleTagRemove(option, $event)"
                          >
                            <span class="multiselect-tag-remove-icon"></span>
                          </span>
                        </div>
                      </template>
                    </Multiselect>
                  </div>
                </fieldset>
                <div class="form__actions">
                  <button class="btn btn--primary" type="button" @click="ValidatePostData()">Publicar</button>
                </div>
              </form>
            </div>
          </div>
          <div v-else>
            <!--Pregunta-->
            <div class="box box--no-shadow">
              <form class="form">
                <fieldset class="form__set">
                  <legend class="form__legend">Pregunta</legend>
                  <div>
                    <div class="form__group">
                      <textarea class="form__input" v-model="post.desc" required></textarea>
                    </div>
                  </div>
                  <div class="form__group">
                    <label class="form__label" for="link">Enlace externo <small class="text-alt">(Opcional)</small></label>
                    <input type="url" class="form__input" id="link" v-model.trim="post.url" placeholder="Ingresa un enlace">
                  </div>
                  <div class="form__group">
                    <label class="form__label" for="file">Archivo adjunto <small class="text-alt">(Opcional)</small></label>
                    <div class="grid grid-2">
                      <input ref="inputFile" type="file" class="form__input" accept="image/jpg , image/jpeg , image/png , application/pdf" id="file" @change="attachFile($event)">
                      <div class="text-right" v-if="post.file">
                        <button class="btn btn--danger btn--xs" type="button" @click.prevent="removeFile">Eliminar archivo</button>
                      </div>
                    </div>
                  </div>
                </fieldset>
                <fieldset class="form__set">
                  <legend class="form__legend">Sobre la pregunta</legend>
                  <div class="grid grid-2">
                    <div class="form__group">
                      <label class="form__label">Causas <small class="text-alt">(3 máximo)</small></label>
                      <Multiselect
                        v-model="post.causes"
                        :mode="'multiple'"
                        :hideSelected="false"
                        :required="false"
                        :options="causes"
                        valueProp="id"
                        :searchable="true"
                        :noResultsText="'No se encontraron resultados'"
                        :closeOnSelect="false"
                        :max="3"
                        :placeholder="'Seleccione'"
                        ref="causes"
                      >
                        <template v-slot:multiplelabel="{ values }">
                          <div class="multiselect-multiple-label">
                            {{ `${values.length} ${values.length > 1 ? 'causas seleccionadas' : 'causa seleccionada'} `}}
                          </div>
                        </template>
                        <template v-slot:option="{ option }">
                          {{option.name}}<span class="tag tag--primary post__tag">{{option.total}}</span>
                        </template>
                      </Multiselect>
                    </div>
                    <div class="form__group">
                      <label class="form__label">Áreas de interés <small class="text-alt">(3 máximo)</small></label>
                      <Multiselect
                        v-model="post.areas"
                        :mode="'multiple'"
                        :hideSelected="false"
                        :required="false"
                        :options="areas"
                        valueProp="id"
                        :searchable="true"
                        :noResultsText="'No se encontraron resultados'"
                        :closeOnSelect="false"
                        :max="3"
                        :placeholder="'Seleccione'"
                        ref="areas"
                      >
                        <template v-slot:multiplelabel="{ values }">
                          <div class="multiselect-multiple-label">
                            {{ `${values.length} ${values.length > 1 ? 'áreas de interés seleccionadas' : 'área de interés seleccionada'} `}}
                          </div>
                        </template>
                        <template v-slot:option="{ option }">
                          {{option.name}}<span class="tag tag--primary post__tag">{{option.total}}</span>
                        </template>
                      </Multiselect>
                    </div>
                  </div>
                  <div class="form__group">
                    <label class="form__label" for="post_tags">Etiquetas <small class="text-alt">(Opcional)</small> </label>
                    <Multiselect
                      id="post_tags"
                      v-model="post.tags"
                      :required="false"
                      label="name"
                      valueProp="id"
                      value-prop="id"
                      :searchable="true"
                      :noResultsText="'No se encontraron resultados'"
                      :mode="'tags'"
                      :createTag="true"
                      :appendNewOption="true"
                      :max="5"
                      :closeOnSelect="false"
                      :placeholder="'Comienza a escribir para agregar etiquetas'"
                      :min-chars="1"
                      :resolve-on-load="false"
                      :delay="1"
                      :options="tagList"
                      @search-change="async (query) => {
                        return await fetchTags(query)
                      }"
                      ref="tags"
                      >
                      <template v-slot:tag="{ option, handleTagRemove, disabled }">
                        <div class="multiselect-tag is-user" :style="[checkHex(option.hex) ? {'background': option.hex} : {'background': colors[tagHolder.indexOf(option.id)]}]">
                          {{ option.name }}
                          <span
                            v-if="!disabled"
                            class="multiselect-tag-remove"
                            @mousedown.prevent="handleTagRemove(option, $event)"
                          >
                            <span class="multiselect-tag-remove-icon"></span>
                          </span>
                        </div>
                      </template>
                    </Multiselect> 
                  </div>
                </fieldset>
                <div class="form__actions">
                    <!--<button class="btn btn--primary" type="submit" :disabled="submitting">Preguntar</button>-->
                    <button class="btn btn--primary" type="button" @click="ValidateQuestionData()" >Publicar</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section class="extra-content">
      </section>
    </div>
  </div>
  <modal-dialog v-if="showPreview" @close="showPreview = false">
    <template #title>Previsualización de la {{type != 'post' ? 'Pregunta' : 'Publicación'}}</template>
    <template #description>Así lucirá la {{type != 'post' ? 'pregunta' : 'publicación'}} una vez publicada, verifica que esté todo bien antes de confirmar</template>
    <template #content>
      <div class="post-wrapper">
        <div class="post">
          <div class="post__header">
            <div class="post__author">
              <div class="user__image" v-if="$store.state.user.profile_image">
                <img referrerpolicy="no-referrer" :src="$store.state.user.profile_image">
              </div>
              <div class="user__image" v-else>
                <img src="@/assets/images/avatar_default.png">
              </div>
              <div class="user__data">
                <h5>
                  {{ $store.state.user.name }} {{$store.state.user.lastnameA}} {{$store.state.user.lastnameB}}
                </h5>
                <p class="post__time caption text-alt" :title="$moment.utc().local().format('L')">
                  {{$moment.utc(post.createdAt).fromNow()}}
                </p>
              </div>
            </div>
          </div>
          <div class="post__main-content">
            <div class="post__causes-and-areas">
              <img src="@/assets/images/icons/ico_book--gray.svg">
              <template v-for="cause in $refs.causes?.iv" :key="cause.id">
                <span class="caption post__cause">{{cause.name}}</span>
              </template>
              <template v-for="area in $refs.areas?.iv" :key="area.id">
                <span class="caption post__area">{{area.name}}</span>
              </template>
            </div>
            <div class="post__title">
              <h4 v-if="this.post.qnaType == 2">{{post.title}}</h4>
              <h4 v-else>{{post.desc}}</h4>
            </div>
            <div v-if="$refs.tags?.iv" class="post__tags">
              <span class="tag tag--primary post__tag" v-for="(tag,i) in $refs.tags.iv" :key="i">
                {{tag.name}}
              </span>
            </div>
            <div class="post__cover-image" v-if="selectedFile">
              <img v-if="['png', 'jpeg', 'jpg'].includes(post.fileType)" :src="selectedFile"/>
              <br><br>
            </div>
            <div class="post__content" v-if="this.post.qnaType == 2" v-html="post.desc">
            </div>
            <div class="post__content" v-else>
            </div>
            <div class="post__url" v-if="post.url">
              <h5 class="post__url-title">Enlace externo</h5>
              <div class="post__url-info">
                <img class="post__url-icon" src="@/assets/images/icons/ico_external-link--black.svg">
                <a :href="post.url" target="_blank">{{ post.url }}</a>
              </div>
            </div>
            <div class="post__download" v-if="selectedFile">
              <template v-if="['png', 'jpeg', 'jpg'].includes(post.fileType)"/>
              <a v-else class="btn btn--primary" :href="selectedFile" target="_blank">
                <img class="icon" src="@/assets/images/icons/ico_download--white.svg"> Descargar archivo adjunto
              </a>
            </div>
          </div>
          <div class="post__footer">
            <div class="post__interactions-side">
              <div class="post__interaction">
                <button class="btn btn--primary btn--text btn--sm">
                  <img class="icon" src="@/assets/images/icons/ico_heart_filled--orange.svg"/> {{randomNumbers[0]}}
                </button>
              </div>
              <div class="post__interaction">
                <button class="btn btn--primary btn--text btn--sm">
                  <img class="icon" src="@/assets/images/icons/ico_comment--orange.svg"> {{randomNumbers[1]}} {{type != 'post' ? 'Respuestas' : 'Comentarios'}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
        <div class="form__actions">
          <button class="btn btn--primary btn--outline" type="button" @click.prevent="showPreview = false" :disabled="submitting">Volver a editar</button>
          <button v-if="post.qnaType == 1" class="btn btn--primary" type="button" :disabled="submitting" @click.prevent="saveQna()">{{submitting ? 'Guardando...' : 'Confirmar'}}</button>
          <button v-else class="btn btn--primary" type="button" :disabled="submitting" @click.prevent="savePost()">{{submitting ? 'Guardando...' : 'Confirmar'}}</button>
        </div>
    </template>
  </modal-dialog>
</template>

<script>
import SideNav from '@/components/SideNav.vue'
import Multiselect from '@vueform/multiselect'
import ModalDialog from '@/components/ModalDialog.vue'
import RichEditor from '@/components/RichEditor.vue'

export default {
  components: { SideNav, Multiselect, ModalDialog , RichEditor},
  name: 'NewPost',
  data(){
    return {
      type: 'post',
      questionType: 'text',
      areas: {},
      causes: {},
      tags: {},
      post: {
        areas: [],
        causes: [],
        tags: [],
        title: '',
        desc: '',
        qnaType: 2,
        url: '' ,
        file: null,
        fileType: null
      },
      colors: ['#DAF7A6', '#FFC300', '##FF5733', '#16A085', '#34495E'],
      submitting: false,
      videoUrl: null,
      options: {
        placeholder: "Escribe aquí...",
        theme: 'snow',
        //formats : [... "height", "width", "class", "style"],
        modules: {               
          toolbar: {
            container: [
              [
                'bold', 'italic', 'underline',
                { align: '' }, { align: 'center' },{ align: 'right' }, { align: 'justify' },
                { 'list': 'ordered' }, { 'list': 'bullet' }, 'video'
                //'image', 
              ],
            ],
            /*handlers: {
              image: imageHandler
            }*/
          }
        }
      },
      tagHolder: [],
      showPreview: false,
      tagList: [],
      extensions:{
        png: 'image/png',
        jpeg: 'image/jpeg',
        jpg: 'image/jpeg',
        pdf: 'application/pdf'
      },
      selectedFile: null,
      randomNumbers: [Math.floor(Math.random() * 21) + 1 , Math.floor(Math.random() * 21) + 1]
    }
  },
  async mounted() {
    this.areas = ((await this.$axios.get(`areas`)).data).sort((a , b) => b.total - a.total)
    this.causes = ((await this.$axios.get('causas')).data).sort((a , b) => b.total - a.total)
    this.tags = (await this.$axios.get('tags/popular')).data

  },
  methods: {
    removeFile(){
      this.$refs.inputFile.value = null
      this.post.file = null
      this.post.fileType = null
      this.selectedFile = null
    },
    attachFile(e){
      const file = e.target.files[0]
      if(file){
        if(this.extensions[file.name.split('.').pop()]){
          this.getBase64Image(file)
          this.selectedFile = URL.createObjectURL(file)
        }else{
          this.post.file = null
          this.post.fileType = null
          this.selectedFile = null
          return this.$toast.error("El tipo de archivo seleccionado no esta permitido")
        }
      }else{
        this.post.file = null
        this.post.fileType = null
        this.selectedFile = null
      }
    },
    getBase64Image(archive) {
      var reader = new FileReader()
      reader.onload = () => {
        this.post.file = (reader.result).replace(`data:${this.extensions[archive.name.split('.').pop()]};base64,` , '')
        this.post.fileType = archive.name.split('.').pop()
      }
      reader.onerror = (error) => {
        console.log('Error: ', error)
      }
      reader.readAsDataURL(archive)
    },
    setEditorContent(ed){
      this.post.desc = ed
    },
    ValidatePostData(){
      if(this.post.url !== '' && !this.checkUrl(this.post.url)){
        return this.$toast.error('El enlace no es válido.')
      }
      if(this.post.title != null && this.post.title.length > 0){
        if (this.post.desc != null && this.post.desc.length > 0 && this.post.desc != "<p><br></p>") {
          if (this.post.areas.length > 0 || this.post.causes.length > 0) {
            this.showPreview = true
          } else {
            this.$toast.error('Debes seleccionar al menos una causa y/o área de interés')
          }
        } else{
          switch(this.questionType){
            case 'text':
              this.$toast.error('Debes ingresar contenido a la publicación')
              break
            case 'image':
              this.$toast.error('Debes seleccionar una imagen')
              break
            case 'video':
              this.$toast.error('Debes ingresar un enlace para video')
              break

          }
        }
      }else{
        this.$toast.error('Debes ingresar un título para la publicación')
      }
    },
    ValidateQuestionData(){
      if(this.post.url !== '' && !this.checkUrl(this.post.url)){
        return this.$toast.error('El enlace no es válido.')
      }
      if (this.post.desc != null && this.post.desc.length > 0) {
        if (this.post.areas.length > 0 || this.post.causes.length > 0) {
          this.showPreview = true
        } else {
          this.$toast.error('Debes seleccionar al menos una causa y/o área de interés')
        }
      } else{
        this.$toast.error('Debes crear la pregunta antes de publicar')
      }
    },
    changeType(type, n){
      this.type = type
      this.post.qnaType = n
      this.videoUrl = null
      this.post.areas = []
      this.post.causes = [],
      this.post.tags = [],
      this.post.title = '',
      this.post.desc = null
      this.post.url = ''
    },
    changeQuestionType(type){
      this.questionType = type
      this.videoUrl = null
      if (type == 'imagen')
        this.post.desc = null
    },
    async saveQna(){
      this.submitting = true;
      const postCopy = this.post
      if (this.post.qnaType == 1) {
        postCopy.title = postCopy.desc
      }

      const res = (await this.$axios.post('Qna/Create', postCopy))
      if (res.status == 200) {
        this.$toast.success(`${this.type != 'post' ? 'La pregunta' : 'La publicación'} ha sido creada exitosamente`)
        this.$router.push({name: 'Home'})
      }
      else if(res.status == 203){
        this.$toast.error(res.data, {duration: 6000})
        this.submitting = false
      }
      else{
        this.$toast.error(`Ocurrió un error al crear la ${this.type != 'post' ? 'pregunta' : 'publicación'}`)
        this.submitting = false;
      }
    },
    ChangeVideoPreview() {
      var box = document.getElementById('videoPreview')
      var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
      var match = this.videoUrl.match(regExp)
      if (match && match[7].length == 11) {
        var b = match[7]
        box.innerHTML = `<iframe width="560" height="315" src="https://www.youtube.com/embed/${b}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
        this.post.desc = `<iframe width="560" height="315" src="https://www.youtube.com/embed/${b}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
      } else {
        this.post.desc
        this.$toast.error('La URL del video ingresada no es válida')
      }
    },
    async fetchTags(query){
      //Corregir error de s.value
      let data = null
      await this.$nextTick()
      if(query == null || query == '')
        data = (await this.$axios.get('tags/popular')).data
      else
        data = (await this.$axios.get(`tags/search-by/${query}`)).data
      this.tagList = data
    },
    checkHex(input) {
      if(input == null) return false
      var check, len
      if(typeof input == 'string') {
        if(input[0] === "#") {
          len = input.length
          if (len === 4 || len === 7) { 
            input = input.toLowerCase()
            check = '#' + ('00000000' + parseInt(input.substr(1), 16).toString(16)).substr(1 - len)
            return check === input
          }
        }
      }
      return false
    },
    checkUrl(string) {
      let givenURL
      try {
        givenURL = new URL(string);
      } catch (error) {
        //console.log("error is",error)
        return false
      }
      return givenURL.protocol === "http:" || givenURL.protocol === "https:"
    },
    async savePost() {
      this.submitting = true
      const res = (await this.$axios.post('Qna/Create', this.post))
      if (res.status == 200) {
        this.$toast.success('La publicación ha sido creada con exitosamente')
        this.$router.push({name: 'Home'})
      }
      else if(res.status == 203){
        this.$toast.error(res.data, {duration: 8000})
        this.submitting = false
      }
      else {
        this.$toast.error('Ocurrió un error al crear la publicación')
        this.submitting = false
      }
    }
  }
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>